import React from "react"
import { navigate } from "gatsby"
import Footer from "./../components/footer"
import IntroHeader from "./../components/intro-header"
import SEO from "./../components/seo"
import Video from "./../components/video"
import resilienceImage from "./../assets/images/takethetest.svg"
import journeyImage from "./../assets/images/chooseajourney.svg"
import habitsImage from "./../assets/images/buildnewhabits.svg"
import { Trans, withTranslation } from "react-i18next"
const featureTilesContent = [
  {
    image: resilienceImage,
    title: <Trans i18nKey={"welcome.featureTilesContent.title_one"} />,
    description: (
      <Trans i18nKey={"welcome.featureTilesContent.description_one"} />
    ),
  },
  {
    image: journeyImage,
    title: <Trans i18nKey={"welcome.featureTilesContent.title_two"} />,
    description: (
      <Trans i18nKey={"welcome.featureTilesContent.description_two"} />
    ),
  },
  {
    image: habitsImage,
    title: <Trans i18nKey={"welcome.featureTilesContent.title_three"} />,
    description: (
      <Trans i18nKey={"welcome.featureTilesContent.description_three"} />
    ),
  },
]

const Welcome = ({ location }) => {
  const goToLogin = () => {
    localStorage.setItem("welcomeDismissed", true)
    navigate("/login")
  }

  return (
    <div className="container">
      <div className="layout">
        <SEO title="Welcome" />
        <IntroHeader path={location.pathname} />
        <main>
          <section className="deck center-align">
            <header className="deck__viewport-height">
              <div className="row no-margin">
                <div className="col l8 offset-l2 s12">
                  <h1 className="big-title uppercase no-margin">
                    <Trans i18nKey={"welcome.title_welcome"} />
                    <span className="deck__welcome-text">
                      {" "}
                      <Trans i18nKey={"welcome.text_change_app"} />
                    </span>
                  </h1>
                  <h2 className="text deck__title margin--top-10">
                    <Trans i18nKey={"welcome.opportunity_text"} />
                  </h2>
                </div>
              </div>
              <div className="deck__video">
                <Video
                  videoUrl="https://player.vimeo.com/external/368985777.hd.mp4?s=8db461052551299f3909408fed8abfd665b65ef2&profile_id=175"
                  thumbnail="https://i.vimeocdn.com/video/825818122.png?mw=800&mh=400"
                />
              </div>
              <div className="deck__actions-wrapper center-align">
                <button
                  className="button button--no-flex margin--top-40 margin--bottom-16"
                  onClick={() => goToLogin()}
                >
                  <Trans i18nKey={"welcome.start_button"} />
                </button>
              </div>
            </header>
            <article>
              <h2 className="big-title uppercase no-marginbottom margin--top-100">
                <Trans i18nKey={"welcome.how_it_work"} />
              </h2>
              <div className="feature-tiles">
                {featureTilesContent.map((tile, index) => (
                  <div className="feature-tiles__tile" key={index}>
                    <img
                      className="feature-tiles__image"
                      src={tile.image}
                      alt="Resilience test"
                    />
                    <p className="feature-tiles__title feature-head uppercase">
                      {tile.title}
                    </p>
                    <p className="feature-tiles__description feature-text">
                      {tile.description}
                    </p>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col l8 offset-l2 s12">
                  <div className="center-align">
                    <h1 className="big-title uppercase no-marginbottom margin--top-100">
                      <Trans i18nKey={"welcome.about_text"} />
                    </h1>
                    <p className="text margin--top-10 margin--bottom-70">
                      <Trans i18nKey={"welcome.about_text_description_one"} />
                      <br />
                      <br />
                      <Trans i18nKey={"welcome.about_text_description_two"} />
                      <br />
                      <br />
                      <Trans i18nKey={"welcome.about_text_description_three"} />
                      <br />
                      <br />
                      <Trans i18nKey={"welcome.about_text_description_four"} />
                      <br />
                      <a
                        className="link"
                        href="https://1change.co/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        www.1change.co
                      </a>
                    </p>
                    <button className="button" onClick={() => goToLogin()}>
                      <Trans i18nKey={"welcome.goToLogin"} />
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default withTranslation()(Welcome)
